html {
  font-family: 'Montserrat', sans-serif;
}

body {
  margin: 0;
}

#root {
  height: 100vh;
}